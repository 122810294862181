import * as React from 'react';
import { Link, HeadFC } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import BackToHomepage from '../assets/back-to-homepage.svg';

import {
  pageStyles,
  headingStyles,
  paragraphStyles,
  imageContainer,
} from '../styles/pages/utility/styles.module.css';

const NotFoundPage = () => {
  return (
    <Layout>
      <main className={pageStyles}>
        <div className={imageContainer}>
          <StaticImage src="../images/404.png" alt="404 Image" />
        </div>
        <h1 className={headingStyles}>Page not found</h1>
        <p className={paragraphStyles}>
          The page you’re looking for doesn’t exist.
        </p>
        <div>
          <Link to="/">
            <BackToHomepage />
          </Link>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
